<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    this.$router
      .push({
        name: 'ChildrenYourChildren'
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
</script>
